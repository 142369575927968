import React from "react";
import { Container, Typography, Box } from "@mui/material";
import ContactInfo from "../components/ContactInfo";
import TextImageSection from "../components/TextImageSection";
import CampaignOffer from "../components/CampaignOffer";
import sampleImage from "../assets/sampleImage.jpg";
import darkImage from "../assets/darkImage.jpg";
import garo from "../assets/garo.jpg";
import laddkabel from "../assets/laddkabel.jpg";

const Home = () => {
  return (
    <Container>
      <Box
        sx={{
          width: "100%",
          height: 300,
          backgroundImage: `url(${darkImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          marginBottom: 2,
        }}
      />
      <Typography variant="body1">
        Företaget startas hösten 2019 av Magnus Gustafsson och John Smith.
        Tillsammans har vi 30 års erfarenhet inom yrket som startades tidigt
        2000 tal
      </Typography>

      <ContactInfo
        phone="+46 73 612 06 40"
        email="info@kintab.com"
        address="Kungsgatan 1, 111 43 Stockholm"
      />
      <h1>Kampanjerbjudanden</h1>
      <CampaignOffer
        imageSrc={garo}
        title="Garo sommarkampanj"
        description="GARO GLB+ är en smart high end OCPP laddbox som är redo att anslutas mot backend-server med OCPP protokoll. Laddboxen kan också konfigureras med dynamisk lastbalansering, vilket minimerar risken för överbelastning av säkringar i matande elcentral."
        discountPrice="499"
        regularPrice="799"
        buttonText="Produktinformation"
        buttonUrl="https://www.garo.se/sv/proffs/produkter/e-mobility/laddbox/laddbox-singel-operator-ocpp-glb/laddbox-glb-t2ev-22kw-m-r-l0"
        validity="2024-12-31"
      />
      <CampaignOffer
        imageSrc={laddkabel}
        title="Laddkabel"
        description="Köp nu och spara stort på denna tjänst!"
        discountPrice="199"
        regularPrice="399"
        buttonText="Läs mer om produkten hos Garo"
        buttonUrl="https://www.garo.se/sv/proffs/produkter/e-mobility/tillbehor-e-mobility/laddkabel/laddkabel-t1pc-1f20a250v-6m-s"
        validity="2024-12-01" // Detta erbjudande visas inte eftersom datumet har passerat
      />
      <TextImageSection
        title="Våra tjänster"
        text="Vi erbjuder en mängd tjänster och löser det mesta inom el och bygg. Allt ifrån elbilsladdare och ställverk till nybyggnation eller finsnickeri."
        imageUrl={sampleImage}
        imageFirst={true} // Bilden visas till vänster
      />
      <TextImageSection
        title="Om oss"
        text="Vårt mål är att tillhandahålla helhets baserade lösningar till dig som kund. Utföra projektering, konsultuppdrag, service och installation med hög kvalité inom både el- och byggbranschen."
        imageUrl={sampleImage}
        imageFirst={false} // Bilden visas till höger
      />
    </Container>
  );
};

export default Home;
