import React from "react";
import { Box, Typography } from "@mui/material";

const ImageTextSection = ({
  imageUrl,
  subheader1,
  text1,
  subheader2,
  text2,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "40px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          padding: "20px",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        {subheader1 && (
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            {subheader1}
          </Typography>
        )}
        {text1 && (
          <Typography variant="body1" sx={{ marginBottom: "20px" }}>
            {text1}
          </Typography>
        )}

        {subheader2 && (
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            {subheader2}
          </Typography>
        )}
        {text2 && <Typography variant="body1">{text2}</Typography>}
      </Box>

      {imageUrl && (
        <Box sx={{ flex: 1, padding: "20px" }}>
          <img
            src={imageUrl}
            alt="Section Image"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ImageTextSection;
