import React from "react";
import { Box, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";

const ProfileSection = ({
  imageUrl,
  name,
  title,
  phone,
  email,
  address,
  imageLeft,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: imageLeft ? "row" : "row-reverse" },
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "40px",
      }}
    >
      {imageUrl && (
        <Box sx={{ flex: 1, padding: "20px" }}>
          <img
            src={imageUrl}
            alt="Profile"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
            }}
          />
        </Box>
      )}

      <Box
        sx={{
          flex: 1,
          padding: "20px",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        {name && (
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            {name}
          </Typography>
        )}
        {title && (
          <Typography
            variant="subtitle1"
            sx={{ fontStyle: "italic", marginBottom: "20px" }}
          >
            {title}
          </Typography>
        )}
        {phone && (
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
          >
            <PhoneIcon sx={{ marginRight: "10px" }} />
            <Typography variant="body1">{phone}</Typography>
          </Box>
        )}
        {email && (
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
          >
            <EmailIcon sx={{ marginRight: "10px" }} />
            <Typography variant="body1">{email}</Typography>
          </Box>
        )}
        {address && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <HomeIcon sx={{ marginRight: "10px" }} />
            <Typography variant="body1">{address}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProfileSection;
