import React from "react";
import { Container, Typography } from "@mui/material";
import ProfileSection from "../components/ProfileSection";
import profileImage from "../assets/profileImage.jpg";

const About = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Kontakt
      </Typography>
      <Typography variant="body1">
        Detta är startsidan. Utforska gärna våra tjänster och lär dig mer om
        oss.
      </Typography>
      <ProfileSection
        imageUrl={profileImage}
        name="Ola Nilsson"
        title="Creative Developer & Designer"
        phone="+46 70 123 4567"
        email="ola.nilsson@example.com"
        address="Exempelgatan 1, 123 45 Stockholm"
        imageLeft={true} // Bilden visas till vänster på större skärmar
      />

      <ProfileSection
        imageUrl={profileImage}
        name="Anna Andersson"
        title="Project Manager"
        phone="+46 70 765 4321"
        email="anna.andersson@example.com"
        address="Testvägen 10, 543 21 Göteborg"
        imageLeft={false} // Bilden visas till höger på större skärmar
      />
    </Container>
  );
};

export default About;
