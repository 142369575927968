import React from "react";
import { Container, Typography } from "@mui/material";
import ImageTextSection from "../components/ImageTextSection";
import sampleImage from "../assets/sampleImage.jpg";

const About = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Om oss
      </Typography>

      <ImageTextSection
        subheader1="Företaget"
        text1="Företaget startas hösten 2019 av Magnus Gustafsson och John Smith.
            Tillsammans har vi 30 års erfarenhet inom yrket som startades tidigt 2000 tal.
            Började inom elinstallations yrket där vi båda har haft rollen som ledande   montör sam projekterat och drivit små som stora projekt.
            Av ren nyfikenhet började vi verka inom kraftmontage med allt va det   innebär.  
            Även inom detta område hade vi ledande roller.
            De senaste 5 åren har vi arbetat inom eldistribution där vi främst har haft 
            hand om elnät"
        subheader2="Vår affärsidé"
        text2="Vår affärsidé är att tillhandahålla helhets baserade lösningar till kund. Utföra projektering, konsultuppdrag, service och installation med hög kvalité inom elbranschen.
            Vår vision är att utveckla Kinta AB till ett av de bästa företagen inom verksamhetsområdet.
            Med kundfokus, ständiga förbättringar inom företaget är vår vision att vara en attraktiv entreprenör."
        imageUrl={sampleImage}
      />

      <ImageTextSection
        subheader1="Våra tjänster"
        text1="Vi erbjuder webbdesign, utveckling och IT-konsultation."
        imageUrl={sampleImage}
      />
    </Container>
  );
};

export default About;
