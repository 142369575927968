import React from "react";
import { Container, Typography } from "@mui/material";
import TextImageSection from "../components/TextImageSection";
import sampleImage from "../assets/sampleImage.jpg";

const Services = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Våra Tjänster
      </Typography>
      <Typography variant="body1">
        Vi erbjuder och löser det mesta inom både el och bygg. Läs mer nedan
        inom vilka områden vi arbetar.
      </Typography>
      <TextImageSection
        title="Elektriker"
        text="Vi är elektriker med kunskap inom både hög och lågspänning."
        imageUrl={sampleImage}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Sol"
        text="Vill du bygga en solcellsanläggning har vi lång erfarenhet om detta."
        imageUrl={sampleImage}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Ladda bil"
        text="Behöver du en elbilsladdare? Vi har flera olika modeller och märken. Vi installerar alla!"
        imageUrl={sampleImage}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Ställverk och eljour"
        text="Vi har lång erfarenhet från ställverksarbeten och eljour."
        imageUrl={sampleImage}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Nybyggnation och byggservice"
        text="Oavsett om ni vill ha hjälp med att bygga ett helt nytt hus eller en tillbyggnad så har vi duktiga snickare."
        imageUrl={sampleImage}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Totalentreprenader"
        text="Vi tar hand om hela byggprocessen, projektleder och håller dig uppdaterad under hela processen hela vägen hem."
        imageUrl={sampleImage}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Snickeri samt finsnicker"
        text="Snickeri som skapar skräddarsydda finsnickerier med fokus på detaljer, funktion & kvalité."
        imageUrl={sampleImage}
        imageFirst={false} // Bilden visas till höger
      />
    </Container>
  );
};

export default Services;
