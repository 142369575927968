import React from "react";
import { Box, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ContactInfo = ({ phone, email, address }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Kolumn på mobiler, rad på större skärmar
        justifyContent: "space-around",
        alignItems: "center",
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#f0f0f0",
        borderRadius: "8px",
      }}
    >
      <Box sx={{ margin: "10px" }}>
        <PhoneIcon
          sx={{ fontSize: "40px", marginBottom: "10px", color: "#333" }}
        />
        <Typography variant="h6">Telefon</Typography>
        <Typography variant="body1">{phone}</Typography>
      </Box>

      <Box sx={{ margin: "10px" }}>
        <EmailIcon
          sx={{ fontSize: "40px", marginBottom: "10px", color: "#333" }}
        />
        <Typography variant="h6">E-post</Typography>
        <Typography variant="body1">{email}</Typography>
      </Box>

      <Box sx={{ margin: "10px" }}>
        <LocationOnIcon
          sx={{ fontSize: "40px", marginBottom: "10px", color: "#333" }}
        />
        <Typography variant="h6">Adress</Typography>
        <Typography variant="body1">{address}</Typography>
      </Box>
    </Box>
  );
};

export default ContactInfo;
