import React from "react";
import { Box, Typography, Button } from "@mui/material";
import PropTypes from "prop-types";

const CampaignOffer = ({
  imageSrc,
  title, // Ny prop för titeln
  description,
  discountPrice,
  regularPrice,
  validity,
  buttonText, // Ny prop för knapptext
  buttonUrl, // Ny prop för knappens länk
}) => {
  // Konvertera validity till ett datumobjekt
  const validityDate = new Date(validity);
  const today = new Date();

  // Kontrollera om giltighetsdatumet har passerat
  if (validityDate < today) {
    return null; // Om datumet har passerat, rendera inte komponenten
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Ändrat till column för att inkludera titel och knapp
        alignItems: "flex-start",
        padding: "16px",
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
      }}
    >
      {/* Titel */}
      {title && (
        <Typography
          variant="h5"
          sx={{ marginBottom: "8px", fontWeight: "bold" }}
        >
          {title}
        </Typography>
      )}

      {/* Bild och textsektion */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        {/* Bild */}
        <Box
          component="img"
          src={imageSrc}
          alt="Kampanjbild"
          sx={{
            width: "150px",
            height: "150px",
            borderRadius: "8px",
            objectFit: "cover",
            marginRight: "16px",
          }}
        />

        {/* Textsektion */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            {description}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              sx={{ color: "#d32f2f", marginRight: "16px" }}
            >
              {discountPrice} SEK
            </Typography>
            <Typography
              variant="body2"
              sx={{ textDecoration: "line-through", color: "#9e9e9e" }}
            >
              Ord. pris: {regularPrice} SEK
            </Typography>
          </Box>

          <Typography
            variant="body2"
            sx={{ marginTop: "8px", color: "#757575" }}
          >
            Gäller till och med {validity}
          </Typography>
        </Box>
      </Box>

      {/* Knapp */}
      {buttonText && buttonUrl && (
        <Button
          variant="contained"
          color="primary"
          href={buttonUrl}
          target="_blank" // Öppnar länken i en ny flik
          sx={{ alignSelf: "flex-start" }} // Justerar knappen till vänster
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

// Proptypes för att säkerställa korrekt användning
CampaignOffer.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  discountPrice: PropTypes.string.isRequired,
  regularPrice: PropTypes.string.isRequired,
  validity: PropTypes.string.isRequired, // Datum i formatet YYYY-MM-DD
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
};

export default CampaignOffer;
