import React from "react";
import { AppBar, Toolbar, Typography, Tabs, Tab, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import kintabLogo from "../assets/kintablogo.png"; // Importera logotypen

const Navbar = () => {
  const location = useLocation();
  const currentTab = location.pathname;

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#f0f0f0",
        color: "#333333",
        marginBottom: "20px",
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={kintabLogo}
            alt="Kintab Logo"
            style={{ height: "40px", marginRight: "10px" }}
          />
          <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 1, textTransform: "uppercase" }}
          >
            Kintab
          </Typography>
        </Box>
      </Toolbar>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Tabs value={currentTab} textColor="inherit" indicatorColor="primary">
          <Tab label="Hem" value="/" component={Link} to="/" />
          <Tab label="Om oss" value="/about" component={Link} to="/about" />
          <Tab
            label="Tjänster"
            value="/services"
            component={Link}
            to="/services"
          />
          <Tab
            label="Kontakt"
            value="/contact"
            component={Link}
            to="/contact"
          />
        </Tabs>
      </Box>
    </AppBar>
  );
};

export default Navbar;
